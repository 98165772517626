import React, { useEffect, useState } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { H3, Icons } from '../../../components';

import css from './MainPanelHeader.module.css';
import { TopbarFilterTypes } from '../../../util/enums';
import { fetchListings } from '../SearchPage.duck';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../../util/urlHelpers';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    isSortByActive,
    noResultsInfo,
    intl,
    config,
    infoOnlyLabel,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [infoOnlyChecked, setInfoOnlyChecked] = useState(false);
  const { onSetGlobalFilterModal } = useMyContextFunctions();

  const classes = classNames(rootClassName || css.root, className);

  const search = parse(location.search);

  useEffect(() => {
    if (search?.pub_isInfoOnly === false && !infoOnlyChecked) {
      setInfoOnlyChecked(true);
    }
  }, [search]);

  const handleInfoOnlyChange = ({ target: { checked } }) => {
    setInfoOnlyChecked(checked);

    if (checked) {
      search.pub_isInfoOnly = false;
    } else {
      delete search.pub_isInfoOnly;
    }

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  };

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <H3 as="h1" className={css.heading}>
          {intl.formatMessage({ id: 'MainPanelHeader.title' })}
        </H3>
        <div className={css.rightSideFilters}>
          <div
            className={css.filterBtn}
            role="button"
            onClick={() => setInfoOnlyChecked(!infoOnlyChecked)}
          >
            <input
              id="infoOnly"
              type="checkbox"
              checked={infoOnlyChecked}
              onChange={handleInfoOnlyChange}
            />
            <label htmlFor="infoOnly">{infoOnlyLabel}</label>
          </div>
          <div
            className={css.filterBtn}
            onClick={() => onSetGlobalFilterModal(true, TopbarFilterTypes.LOCATION)}
          >
            <Icons name="filter" />
            {intl.formatMessage({ id: 'MainPanelHeader.filters' })}
          </div>
          {isSortByActive ? <div className={css.sortyByWrapper}>{sortByComponent}</div> : null}
        </div>
      </div>

      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
