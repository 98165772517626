import React, { useState } from 'react';
import { string, bool, object, node, number, shape, arrayOf } from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import css from './SearchFiltersMobile.module.css';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { Button, Icons } from '../../../components';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchListings } from '../SearchPage.duck';

function SearchFiltersMobileComponent(props) {
  const {
    rootClassName,
    className,
    sortByComponent,
    noResultsInfo,
    intl,
    config,
    infoOnlyLabel,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const { onSetGlobalFilterModal } = useMyContextFunctions();
  const [infoOnlyChecked, setInfoOnlyChecked] = useState(false);

  const classes = classNames(rootClassName || css.root, className);

  const handleInfoOnlyChange = event => {
    const isChecked = event.target.checked;
    setInfoOnlyChecked(isChecked);
    dispatch(
      fetchListings({
        search: location?.search,
        isInfoOnly: isChecked,
        config,
      })
    );
  };

  return (
    <div className={classes}>
      <div className={css.rightSideFilters}>
        <Button
          className={css.filterBtn}
          type="button"
          onClick={() => onSetGlobalFilterModal(true)}
        >
          <Icons name="filter" /> {intl.formatMessage({ id: 'MainPanelHeader.filters' })}
        </Button>
        <div className={css.filterBtn}>
          <input
            id="infoOnly"
            type="checkbox"
            checked={infoOnlyChecked}
            onChange={handleInfoOnlyChange}
          />
          <label htmlFor="infoOnly">{infoOnlyLabel}</label>
        </div>
        {/* <div className={css.sortyByWrapper}>{sortByComponent}</div> */}
      </div>
      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sortByComponent: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
  isMapVariant: true,
  onMapIconClick: () => {},
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  // from useIntl
  intl: intlShape.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

function SearchFiltersMobile(props) {
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();

  return (
    <SearchFiltersMobileComponent
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      {...props}
    />
  );
}

export default SearchFiltersMobile;
